import React from "react";
import { Link } from "react-router-dom";

import Helmet from "../components/Helmet";

const pages = [
    {
        title: "Home",
        url: "/",
    },
    {
        title: "Zonnepanelen",
        childPages: [
            {
                title: "Producten",
                url: "/producten/",
            },
            {
                title: "Over zonnepanelen",
                url: "/zonnepanelen/",
            },
            {
                title: "Voordelen van zonnepanelen",
                url: "/zonnepanelen/voordelen/",
            },
            {
                title: "Mono of Poly zonnepanelen",
                url: "/zonnepanelen/mono-of-poly/",
            },
            {
                title: "Zakelijk zonnepanelen",
                url: "/zonnepanelen/zakelijk/",
            },
            {
                title: "Zonnepanelen voor tuinders",
                url: "/zonnepanelen/tuinders/",
            },
            {
                title: "Installeren van zonnepanelen",
                url: "/zonnepanelen/installeren/",
            },
            {
                title: "0% btw",
                url: "/btw/",
            },
            {
                title: "BTW Terugvraag",
                url: "/btwterugvraag/",
            },
        ],
    },
    {
        title: "Thuisbatterij",
        childPages: [
            {
                title: "Overzicht",
                url: "/thuisbatterij/",
            },
            {
                title: "Hoe werkt het?",
                url: "/thuisbatterij/hoe-werkt-het/",
            },
            {
                title: "Installatie",
                url: "/thuisbatterij/installatie/",
            },
            {
                title: "Zakelijk",
                url: "/thuisbatterij/zakelijk/",
            },
            {
                title: "BTW Terugvragen",
                url: "/thuisbatterij/btwterugvraag/",
            },
        ],
    },
    {
        title: "Laadpalen",
        url: "/laadpalen/",
    },
    {
        title: "Energie Management Systeem",
        url: "/ems/",
    },
    {
        title: "Subsidie",
        url: "/subsidie/",
    },
    {
        title: "Support",
        url: "/support/",
        childPages: [
            {
                title: "Energieleveren.nl",
                url: "/energieleveren/",
            },
            {
                title: "Conformiteitscertificaat omvormers",
                url: "/support/conformiteitscertificaat/",
            },
        ],
    },
    {
        title: "Contact",
        url: "/contact/",
        childPages: [
            {
                title: "Offerte aanvragen",
                url: "/offerte/",
            },
        ],
    },
    {
        title: "SolarEdge Installer Plus",
        url: "/solaredge-installer-plus/",
    },
    {
        title: "Monitoring",
        childPages: [
            {
                title: "SolarEdge",
                url: "/solaredge/",
            },
            {
                title: "Afore",
                url: "/afore/",
            },
            {
                title: "Afore Solarman",
                url: "/solarman/",
            },
            {
                title: "Afore Solarman 3",
                url: "/solarman3/",
            },
            {
                title: "APsystems",
                url: "/apsystems/",
            },
            {
                title: "GoodWe",
                url: "/goodwe/",
            },
            {
                title: "Growatt",
                url: "/growatt/",
            },
            {
                title: "SolaX",
                url: "/solax/",
            },
            {
                title: "SolaX Portal",
                url: "/solaxportal/",
            },
            {
                title: "Solis",
                url: "/solis/",
            },
            {
                title: "Solis Cloud",
                url: "/soliscloud/",
            },
            {
                title: "Solplanet",
                url: "/solplanet/",
            },
            {
                title: "KSTAR",
                url: "/kstar/",
            },
        ],
    },
    {
        title: "Informatie avond",
        url: "/info-avond/",
    }
];

function Sitemap() {
    return (
        <React.Fragment>
            <Helmet title="Sitemap" />

            <div className="jumbotron jumbotron-fluid jumbotron-header">
                <div className="container">
                    <h1>Sitemap</h1>
                </div>
            </div>

            <div className="container bigger-paragraphs">
                {pages.map((page, index) => (
                    <div className="mb-3" key={index}>
                        {page.url ? (
                            <h3>
                                <Link to={page.url}>{page.title}</Link>
                            </h3>
                        ) : (
                            <h3>{page.title}</h3>
                        )}
                        {page.childPages && (
                            <ul>
                                {page.childPages.map((childPage, index) => (
                                    <li key={index}>
                                        <Link to={childPage.url}>{childPage.title}</Link>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                ))}
            </div>
        </React.Fragment>
    );
}

export default React.memo(Sitemap);
