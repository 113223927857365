import React from "react";
import { Link } from "react-router-dom";

const HomepageCTABlock = ({ title, text, buttonText, buttonLink, img, imgAlt = "", coverImage = false }) => {

    return (
        <div className="cta-container">
            <div className="text-container">
                <div className="title">{title}</div>
                <div className="text">{text}</div>
                <div className="mt-2">
                    <Link to={buttonLink} className="btn btn-primary">
                        {buttonText}
                    </Link>
                </div>
            </div>
            {img && (
                <div className="image-container">
                    <img src={img} alt={imgAlt} className={`${coverImage ? "cover" : "contain"}`}/>
                </div>
            )}
        </div>
    );
};

export default React.memo(HomepageCTABlock);
