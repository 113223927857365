import React, { useState, useCallback, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { Alert } from "react-bootstrap";
import useWindowSize from "../hooks/WindowSize";

const OfferteWidget = ({ predefinedType = null }) => {
    const windowSize = useWindowSize();
    const [activeTab, setActiveTab] = useState(predefinedType ?? "zonnepanelen");
    const [type, setType] = useState(predefinedType ??  "zonnepanelen");
    const [postalCode, setPostalCode] = useState("");
    const [houseNumber, setHouseNumber] = useState("");
    const [addition, setAddition] = useState("");
    const [error, setError] = useState(null);

    const history = useHistory();

    const [isMobile, setIsMobile] = useState(null);

    useEffect(() => {
        setIsMobile(windowSize.width <= 425);
    }, [windowSize]);

    const handleSubmit = useCallback((event) => {
        event.preventDefault();
        setError(null);

        const cleanPostalCode = postalCode.replace(/ /g, "");
        const cleanHouseNumber = houseNumber.replace(/ /g, "");
        const cleanAddition = addition.replace(/ /g, "");

        if(cleanPostalCode.length === 0 && cleanHouseNumber.length === 0) {
            history.push(`/offerte?type=${type}`);
            return;
        }

        const postalCodeRegex = /^[0-9]{4}[A-Za-z]{2}$/;
        if (!postalCodeRegex.test(cleanPostalCode)) {
            setError("Postcode is niet geldig");
            return;
        }

        if (!cleanHouseNumber) {
            setError("Huisnummer is verplicht");
            return;
        }

        const houseNumberComplete = cleanHouseNumber + cleanAddition;

        history.push(`/offerte?type=${type}&postalCode=${cleanPostalCode}&houseNumber=${houseNumberComplete}`);
    }, [postalCode, houseNumber, addition, type, history]);

    const renderForm = useCallback(() => {
        return (
            <div className="mb-4">
                <p className="top-text mb-2 font-weight-bold">Ontvang gratis een offerte</p>
                {error && <Alert variant="danger">{ error }</Alert>}
                <form onSubmit={handleSubmit}>
                    <div className="form-container" style={{ fontSize: "0.8rem" }}>
                        <div className="form-group">
                            <label className="mb-0" htmlFor="postalCode">Postcode</label>
                            <input
                                type="text"
                                className="form-control"
                                id="postalCode"
                                value={postalCode}
                                onChange={(event) => setPostalCode(event.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label className="mb-0" htmlFor="houseNumber">Nummer</label>
                            <input
                                type="number"
                                className="form-control"
                                id="houseNumber"
                                value={houseNumber}
                                onChange={(event) => setHouseNumber(event.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label className="mb-0" htmlFor="addition">Toevoeging</label>
                            <input
                                type="text"
                                className="form-control"
                                style={{borderStyle: "dashed"}}
                                id="addition"
                                value={addition}
                                onChange={(event) => setAddition(event.target.value)}
                            />
                        </div>
                    </div>
                    <button type="submit" className="btn btn-primary w-100 py-2 rounded-lg" style={{ fontSize: "1.2rem" }}>Vraag een offerte aan</button>
                </form>
                <small className="form-text">Binnen een minuutje geregeld!</small>
            </div>
        )
    }, [postalCode, houseNumber, addition, error, handleSubmit]);

    const renderContent = useCallback(() => {
        switch (activeTab) {
            case "zonnepanelen":
                if (type !== "zonnepanelen") {
                    setType("zonnepanelen");
                }
                return (
                    <div className="offerte-content">
                        <div className="offerte-content-usp">
                            <div className="usp">
                                <i className={`fa-solid fa-circle-check ${isMobile ? "fa-lg" : "fa-xl"}`} style={{ color: "#00AA65" }}></i>
                                <span>Bespaar gemiddeld <strong>&euro; 1.235,-</strong> per jaar</span>
                            </div>
                            <div className="usp">
                                <i className={`fa-solid fa-circle-check ${isMobile ? "fa-lg" : "fa-xl"}`} style={{ color: "#00AA65" }}></i>
                                <span>Verduurzaming van uw woning </span>
                            </div>
                            <div className="usp">
                                <i className={`fa-solid fa-circle-check ${isMobile ? "fa-lg" : "fa-xl"}`} style={{ color: "#00AA65" }}></i>
                                <span>Ruim <strong>7.000 </strong> klanten gingen u voor!</span>
                            </div>
                        </div>
                        {renderForm()}
                    </div>
                );
            case "thuisbatterij":
                if (type !== "thuisbatterij") {
                    setType("thuisbatterij");
                }
                return (
                    <div className="offerte-content">
                        <div className="offerte-content-usp">
                            <div className="usp">
                                <i className={`fa-solid fa-circle-check ${isMobile ? "fa-lg" : "fa-xl"}`} style={{ color: "#00AA65" }}></i>
                                <span>Maximale opbrengst voor eigen gebruik</span>
                            </div>
                            <div className="usp">
                                <i className={`fa-solid fa-circle-check ${isMobile ? "fa-lg" : "fa-xl"}`} style={{ color: "#00AA65" }}></i>
                                <span>Bespaar gemiddeld <strong>&euro; 700,-</strong> per jaar</span>
                            </div>
                            <div className="usp">
                                <i className={`fa-solid fa-circle-check ${isMobile ? "fa-lg" : "fa-xl"}`} style={{ color: "#00AA65" }}></i>
                                <span>Dynamisch in- en verkopen van energie </span>
                            </div>
                        </div>
                        {renderForm()}
                    </div>
                );
            case "laadpaal":
                if (type !== "laadpaal") {
                    setType("laadpaal");
                }
                return (
                    <div className="offerte-content">
                        <div className="offerte-content-usp">
                            <div className="usp">
                                <i className={`fa-solid fa-circle-check ${isMobile ? "fa-lg" : "fa-xl"}`} style={{ color: "#00AA65" }}></i>
                                <span>Auto laden met eigen opgewekte energie</span>
                            </div>
                            <div className="usp">
                                <i className={`fa-solid fa-circle-check ${isMobile ? "fa-lg" : "fa-xl"}`} style={{ color: "#00AA65" }}></i>
                                <span>Altijd een laadpunt beschikbaar</span>
                            </div>
                            <div className="usp">
                                <i className={`fa-solid fa-circle-check ${isMobile ? "fa-lg" : "fa-xl"}`} style={{ color: "#00AA65" }}></i>
                                <span>Combinatie zakelijk / privé laden mogelijk </span>
                            </div>
                        </div>
                        {renderForm()}
                    </div>
                );
            default:
                return null;
        }
    }, [activeTab, type, renderForm, isMobile]);

    return (
        <div className="offerte">
            <div className={`offerte-header ${predefinedType ? "text-center" : ""}`}>

                {predefinedType ? (
                    <>
                        <h2>Vraag een offerte aan voor {(predefinedType !== "zonnepanelen") ? "een " : ""} {predefinedType}!</h2>
                    </>
                ) : (
                    <>
                        <button
                            className="offerte-button"
                            onClick={() => setActiveTab("zonnepanelen")}
                        >
                            <i className={`fa-solid fa-solar-panel ${isMobile ? "fa-lg" : "fa-xl"}`}></i>
                            <span className="font-weight-normal" style={isMobile ? { fontSize: "0.9rem", marginTop: "4px" } : {}}>Zonnepanelen</span>
                            <span className={`${activeTab === "zonnepanelen" ? "active" : ""}`}></span>
                        </button>
                        
                        <button
                            className="offerte-button"
                            onClick={() => setActiveTab("thuisbatterij")}
                        >
                            <i className={`fa-solid fa-battery-half ${isMobile ? "fa-lg" : "fa-xl"}`}></i>
                            <span className="font-weight-normal" style={isMobile ? { fontSize: "0.9rem", marginTop: "4px" } : {}}>Thuisbatterij</span>
                            <span className={`${activeTab === "thuisbatterij" ? "active" : ""}`}></span>
                        </button>
                        <button
                            className="offerte-button"
                            onClick={() => setActiveTab("laadpaal")}
                        >
                            <i className={`fa-solid fa-charging-station ${isMobile ? "fa-lg" : "fa-xl"}`}></i>
                            <span className="font-weight-normal" style={isMobile ? { fontSize: "0.9rem", marginTop: "4px" } : {}}>Laadpaal</span>
                            <span className={`${activeTab === "laadpaal" ? "active" : ""}`}></span>
                        </button>
                    </>
                )}
            </div>
            <div className="offerte-content">
                {renderContent()}
            </div>
        </div>
    );
}

export default React.memo(OfferteWidget);
