import React, { useEffect, useRef, useState } from "react";
import Helmet from "../components/Helmet";
import HomepageImageText from "../components/HomepageImageText";
import HomepageCTABlock from "../components/HomepageCTABlock";
import NavbarSettings from "../components/navbar/NavbarSettings";

import banner from "./../img/solar-banner.jpg";
import phoneMockupFilled from "../img/ems/phone-mockup-filled.png";
import particulierenImg from "../img/home/particulieren.jpg";
import thuisbatterijImg from "../img/battery/storedge-48V.png";
import InfoNightSignup from "../components/InfoNightSignup";
import useWindowSize from "../hooks/WindowSize";

function InfoNightRegistration() {
    const bannerRef = useRef(null);
    const windowSize = useWindowSize();
    const [isMobile, setIsMobile] = useState(false);
    
    useEffect(() => {
        setIsMobile(windowSize.width <= 767);
    }, [windowSize]);

    const scrollDown = () => {
        if (bannerRef.current) {
            const bannerHeight = bannerRef.current.offsetHeight;
            window.scrollTo({
                top: bannerHeight - 80,
                behavior: "smooth"
            });
        }
    };

    return (
        <React.Fragment>
            <Helmet/>

            <NavbarSettings>
                <div className="home-banner" ref={bannerRef}>
                    <img src={banner} alt="Foto van zonnepanelen" />
                    <div className="overlay">
                        <div className="content-container">
                            <div className="title-container">
                                <h1 className="title"> Batterij informatie avond</h1>
                                <p className="subtitle">Schrijf je in voor een van onze informatie avonden over thuisbatterijen en GridSense</p>
                            </div>
                            <div className="offerte-widget">
                                <InfoNightSignup isMobile={isMobile}/>
                            </div>
                        </div>
                    </div>
                    <div className="scroll-down-indicator" onClick={scrollDown}>
                        <i className="fas fa-chevron-down fa-2xl" style={{color: isMobile ? "#000000" : "#FFFFFF"}}></i>
                    </div>
                </div>
            </NavbarSettings>

            <div className="container-white-background">
                <div className="container">
                    <h1>Wat kun je verwachten op onze informatieve avond?</h1>
                    <div className="article-open">
                        Tijdens onze informatieavond over thuisbatterijen en GridSense ontdek je alles wat je moet weten over de toekomst van energiebeheer. De volgende onderwerpen zullen uitgebreid aan bod komen:
                        <ul className="topics-list">
                            <li>De voordelen van thuisbatterijen en hoe ze werken</li>
                            <li>Hoe je kunt besparen met dynamische energietarieven</li>
                            <li>Het GridSense Energy Management Systeem</li>
                            <li>Subsidies en terugverdientijden</li>
                            <li>Gelegenheid voor persoonlijke vragen en advies</li>
                        </ul>
                        Schrijf je in voor een van onze informatiesessies en ontdek hoe jij kunt profiteren van de nieuwste ontwikkelingen in energieopslag en -beheer.
                    </div>
                </div>
            </div>
            <div className="container">
                <HomepageImageText
                    title="Thuisbatterijen: Optimaal profiteren van je zonnepanelen"
                    text="Tijdens de informatieavond bespreken we hoe een thuisbatterij je onafhankelijker maakt van het energienet en je energierekening aanzienlijk kan verlagen. Je leert hoe je optimaal gebruik kunt maken van je zelf opgewekte energie, ook als de zon niet schijnt. We behandelen verschillende batterijsystemen, hun capaciteit, levensduur en hoe je de juiste keuze maakt voor jouw specifieke situatie. Ook bespreken we de integratie met bestaande zonnepanelen en het terugverdienmodel bij de huidige en toekomstige energieprijzen."
                    buttonText="Bekijk onze thuisbatterij oplossingen"
                    buttonLink="/thuisbatterij/"
                    img={thuisbatterijImg}
                    imgAlt="Afbeelding van thuisbatterij"
                    isReverse={false}
                />
            </div>

            <div className="container-blue-background">
                <div className="container">
                    <HomepageImageText
                        title="GridSense: Het slimme energiebeheer van de toekomst"
                        text="Maak kennis met GridSense, ons geavanceerde Energy Management Systeem dat het energieverbruik in je woning optimaliseert. Tijdens de informatieavond demonstreren we hoe je via een gebruiksvriendelijke app volledige controle krijgt over je energiestromen. Je leert hoe het systeem automatisch schakelt tussen zonnepanelen, thuisbatterij en netvoeding op basis van actuele prijzen en verbruik. We delen ook onze roadmap voor de toekomstige ontwikkeling van GridSense, waaronder de integratie van dynamische laadpaalsturing voor elektrische voertuigen en hoe je realtime inzicht krijgt in je energieverbruik en -opwekking voor maximale besparingen."
                        buttonText="Ontdek de mogelijkheden van GridSense"
                        buttonLink="/ems/"
                        img={phoneMockupFilled}
                        isReverse={false}
                        underLinedTitle={false}
                    />
                </div>
            </div>
            <HomepageCTABlock
                title="Neem contact op"
                text="Heb je interesse in Gridsense, zonnepanelen, laadpalen of thuisbatterijen? Ons team helpt je graag verder met advies en installatie op maat."
                buttonText="Neem contact op"
                buttonLink="/contact/"
                img={particulierenImg}
            />
        </React.Fragment>
    );
}

export default InfoNightRegistration;
