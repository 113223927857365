import React from "react";
import {
    Link,
    useRouteMatch
} from "react-router-dom";
import NavbarSettings from "../../components/navbar/NavbarSettings";
import SubpageBanner from "../../components/SubPageBanner";

import Helmet from "../../components/Helmet";
import SupportWidget from "../../components/support/SupportWidget";

function SupportWifi() {
    const supportHome = useRouteMatch({ path: "/support/wifi", exact: true });
    return (
        <React.Fragment>
            <Helmet
                title="Wi-Fi handleidingen - Support"
                description="Is de Wi-Fi verbinding met uw omvormer weggevallen? Op deze pagina vind u handleidingen hoe u de verbinding kunt herstellen."
            />

            <NavbarSettings>
                <SubpageBanner
                    title="Support"
                    subtitle="Wi-Fi"
                />
            </NavbarSettings>

            { supportHome && (
                <div className="container text-center my-5">
                    <Link to="/support/" className="btn btn-secondary">
                        <i className="fa-solid fa-chevron-left mr-2"/>
                        Terug naar support
                    </Link>
                </div>
            ) }

            <div className="container text-center mt-3 mb-5">
                <div className="bg-white mb-5 p-5" style={ { borderRadius: "10px", minHeight: "450px" } }>
                    <SupportWidget/>
                </div>
            </div>
        </React.Fragment>
    );
}

export default React.memo(SupportWifi);
