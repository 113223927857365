import React, {
    useMemo
} from "react";
import {
    ButtonGroup,
    ButtonToolbar
} from "react-bootstrap";
import {
    Switch,
    Route,
    Link,
    useRouteMatch
} from "react-router-dom";

import solaredgeLogo from "../../img/brands/solaredge.svg";
import aforeLogo from "../../img/brands/afore.svg";
import solisLogo from "../../img/brands/solis.svg";
import enphaseLogo from "../../img/brands/enphase.svg";
import solplanetLogo from "../../img/brands/solplanet.svg";
import growattLogo from "../../img/brands/growatt.svg";
import apsystemsLogo from "../../img/brands/apsystems.svg";
import goodweLogo from "../../img/brands/goodwe.svg";

import solaredgeHDWave from "../../img/support/solaredge/solaredge-hd-wave.png";
import solaredgeHDWaveNoDisplay from "../../img/support/solaredge/solaredge-hd-wave-no-display.png";
import solaredge3Phase from "../../img/support/solaredge/solaredge-3-phase.png";
import solaredge3PhaseNoDisplay from "../../img/support/solaredge/solaredge-3-phase-no-display.png";

import solaredgeHDWaveManual from "../../files/manuals/solaredge-wifi-hd-wave-manual.pdf";
import solaredge3PhaseManual from "../../files/manuals/solaredge-wifi-3-phase-manual.pdf";

import aforeInverter from "../../img/support/afore/afore-inverter.png";
import aforeWifiAntenna from "../../img/support/afore/afore-wifi-antenna.png";

import aforeOldInvertersManual from "../../files/manuals/afore-wifi-old-inverters-manual.pdf";
import aforeGrayInvertersManual from "../../files/manuals/afore-wifi-antenne-zonder-lampjes.pdf";
import aforeBlackLightsInvertersManual from "../../files/manuals/afore-wifi-antenne-met-lampjes.pdf";

import enphaseEnvoy from "../../files/manuals/enphase-wifi-envoy.pdf";

import solisInvertersManual from "../../files/manuals/solis-wifi-manual.pdf";

import solplanet from "../../files/manuals/solplanet-wifi-manual.pdf";

import growatt from "../../files/manuals/growatt-wifi-manual.pdf";

import goodwe from "../../files/manuals/goodwe-wifi-manual-2.pdf";
import SupportAforeAlert from "./SupportAforeAlert";

function SupportWidgetManualButton({ manual, image, title }) {
    return (
        <ButtonGroup size="lg" className="ml-3 mr-3 mb-4">
            <a href={ manual } className="support-widget-link" target="_blank" rel="noreferrer noopener">
                <img src={ image } height="150px" alt={ title }/>
                <p>{ title }</p>
            </a>
        </ButtonGroup>
    )
}

function SupportWidgetBrandButton({ logo, title, manualUrl, pageUrl }) {
    const logoImg = useMemo(() => {
        return (
            <img
                src={ logo }
                className="img-fluid"
                style={{ maxHeight: "50px" }}
                alt={ title }
            />
        )
    }, [logo, title]);

    const BrandLink = useMemo(() => {
        return ({ children }) => {
            if(manualUrl) {
                return (
                    <a href={ manualUrl } className="support-widget-link" target="_blank" rel="noopener noreferrer">
                        { children }
                    </a>
                )
            }
            return (
                <Link to={ pageUrl } className="support-widget-link">
                    { children }
                </Link>
            )
        }
    }, [manualUrl, pageUrl])

    return (
        <div className="col-xl-4 col-md-6 mb-5">
            <BrandLink>
                { logoImg }
            </BrandLink>
        </div>
    )
}

function SupportWidget() {
    let match = useRouteMatch();

    return (
        <React.Fragment>

            <Switch>

                <Route path={ match.url + "/solaredge" }>
                    <h3>Welk type SolarEdge omvormer heeft u?</h3>
                    <ButtonToolbar className="mt-5 justify-content-center">
                        <SupportWidgetManualButton
                            title="HD-Wave met scherm"
                            manual={ solaredgeHDWaveManual }
                            image={ solaredgeHDWave }
                        />
                        <SupportWidgetManualButton
                            title="HD-Wave zonder scherm"
                            manual="https://youtu.be/wMaJxhl0vl8"
                            image={ solaredgeHDWaveNoDisplay }
                        />
                        <SupportWidgetManualButton
                            title="3 fase met scherm"
                            manual={ solaredge3PhaseManual }
                            image={ solaredge3Phase }
                        />
                        <SupportWidgetManualButton
                            title="3 fase zonder scherm"
                            manual="https://youtu.be/wMaJxhl0vl8"
                            image={ solaredge3PhaseNoDisplay }
                        />
                    </ButtonToolbar>
                    <Link to="/support/wifi/" className="btn btn-link btn-lg mt-5">Terug</Link>
                </Route>

                <Route path={ match.url + "/afore" }>
                    <SupportAforeAlert/>
                    <h3>Welk type Afore omvormer heeft u?</h3>
                    <ButtonToolbar className="mt-5 justify-content-center">
                        <SupportWidgetManualButton
                            title="Vroegere (2015 - 2019)"
                            manual={ aforeOldInvertersManual }
                            image={ aforeInverter }
                        />
                        <SupportWidgetManualButton
                            title="Antenne zonder lampjes"
                            manual={ aforeGrayInvertersManual }
                            image={ aforeInverter }
                        />
                        <SupportWidgetManualButton
                            title="Antenne met lampjes"
                            manual={ aforeBlackLightsInvertersManual }
                            image={ aforeWifiAntenna }
                        />
                    </ButtonToolbar>
                    <Link to="/support/wifi/" className="btn btn-link btn-lg mt-5">Terug</Link>
                </Route>

                <Route path={ match.url }>
                    <h3>Wat is het merk van uw omvormer?</h3>
                    <p className="text-muted">Klik op het merk van uw omvormer.</p>
                    <div className="row justify-content-center">
                        <SupportWidgetBrandButton
                            logo={ solaredgeLogo }
                            title="SolarEdge"
                            pageUrl="/support/wifi/solaredge/"
                        />
                        <SupportWidgetBrandButton
                            logo={ aforeLogo }
                            title="Afore"
                            pageUrl="/support/wifi/afore/"
                        />
                        <SupportWidgetBrandButton
                            logo={ solisLogo }
                            title="Solis"
                            manualUrl={ solisInvertersManual }
                        />
                        <SupportWidgetBrandButton
                            logo={ enphaseLogo }
                            title="Enphase"
                            manualUrl={ enphaseEnvoy }
                        />
                        <SupportWidgetBrandButton
                            logo={ apsystemsLogo }
                            title="APSystems"
                            manualUrl="https://youtu.be/VbWOi9XKEBw"
                        />
                        <SupportWidgetBrandButton
                            logo={ goodweLogo }
                            title="GoodWe"
                            manualUrl={ goodwe }
                        />
                        <SupportWidgetBrandButton
                            logo={ solplanetLogo }
                            title="Solplanet"
                            manualUrl={ solplanet }
                        />
                        <SupportWidgetBrandButton
                            logo={ growattLogo }
                            title="Growatt"
                            manualUrl={ growatt }
                        />
                    </div>
                </Route>

            </Switch>

        </React.Fragment>
    )
}

export default SupportWidget;
