import React from "react";

import ZonnepanelenSubNavbar from "./specific/ZonnepanelenSubNavbar";
import ThuisbatterijSubNavbar from "./specific/ThuisbatterijSubNavbar";
import LaadpaalSubNavbar from "./specific/LaadpaalSubNavbar";

function SubNavbar({ matchZonnepanelen, matchThuisbatterij, matchLaadpalen, isMenuOpen }) {
    
    const renderSubNavbar = () => {
        if (matchZonnepanelen) {
            return <ZonnepanelenSubNavbar />;
        } else if (matchThuisbatterij) {
            return <ThuisbatterijSubNavbar />;
        } else if (matchLaadpalen) {
            return <LaadpaalSubNavbar />;
        } else {
            return null;
        }
    };

    return (
        <>
            {renderSubNavbar() && (
                <div className="sub-navbar navbar-light">
                    <div className="navbar-nav subbar">{renderSubNavbar()}</div>
                </div>
            )}
        </>
    );
}

export default React.memo(SubNavbar);
