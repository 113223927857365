import React, { useState, useCallback, useEffect } from "react";
import moment from "moment";
import { Alert } from "react-bootstrap";
import useWindowSize from "../hooks/WindowSize";
import axios from "axios";

const InfoNightSignup = ({
	title = "Meld je aan voor de informatieavond",
	endpoint = "/registerParticipantToInfoNight",
	isMobile = false
}) => {
	const windowSize = useWindowSize();
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [selectedEvent, setSelectedEvent] = useState("");
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [infoNightEvents, setInfoNightEvents] = useState([]);

	useEffect(() => {
		axios.get("/getInfoNightEvents")
			.then(response => {
				// In Axios, the actual response data is in response.data
				if (response.data.success) {
					console.log(response.data);
					setInfoNightEvents(response.data.events);
				}
			})
			.catch(error => {
				console.error("Error fetching info night events:", error);
			});
	}, []);


	const handleSubmit = useCallback(async (event) => {
		event.preventDefault();
		setSuccess(false);
		setError(null);
		setLoading(true);
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

		// Validation
		if (!name.trim() || !email.trim() || !selectedEvent || !emailRegex.test(email)) {
			setLoading(false);
			setError("Vul alle velden correct in.");
			return;
		}

		// Submit the form
		const data = {
			name,
			email,
			infoNightDateId: selectedEvent,
		};

		try {
			const response = await axios.post(endpoint, data);
			if (response.status === 200) {
				setSuccess(true);
			}
		} catch (err) {
			setError(err.response.data.message);
		} finally {
			setName("");
			setEmail("");
			setSelectedEvent("");
			setLoading(false);
		}
	}, [name, email, selectedEvent, endpoint]);

	return (
		<div className="info-night-widget">
			<div className="info-night">
				<div className="info-night-header">
					<h2>{title}</h2>
				</div>
				<div className="info-night-content">
					<div className="info-night-usp">
						<div className="usp">
							<i className={`fa-solid fa-circle-check ${isMobile ? "fa-lg" : "fa-xl"}`} style={{ color: "#00AA65" }}></i>
							<span>Gratis informatiebijeenkomst</span>
						</div>
						<div className="usp">
							<i className={`fa-solid fa-circle-check ${isMobile ? "fa-lg" : "fa-xl"}`} style={{ color: "#00AA65" }}></i>
							<span>Persoonlijk advies van experts</span>
						</div>
						<div className="usp">
							<i className={`fa-solid fa-circle-check ${isMobile ? "fa-lg" : "fa-xl"}`} style={{ color: "#00AA65" }}></i>
							<span>Inclusief koffie en versnaperingen</span>
						</div>
					</div>
					<div className="mb-3">
						<p className="top-text mb-1 font-weight-bold">Meld je nu aan</p>
						{error && <Alert variant="danger" className="py-1">{error}</Alert>}
						{success && <Alert variant="success" className="py-1">Aanmelding succesvol! U ontvangt een bevestiging per e-mail.</Alert>}
						<form onSubmit={handleSubmit}>
							<div className="form-container-col">
								<div className="form-group">
									<label className="mb-0" htmlFor="name">Naam</label>
									<input
										type="text"
										className="form-control"
										id="name"
										value={name}
										onChange={(event) => setName(event.target.value)}
									/>
								</div>
								<div className="form-group">
									<label className="mb-0" htmlFor="email">E-mail</label>
									<input
										type="email"
										className="form-control"
										id="email"
										value={email}
										onChange={(event) => setEmail(event.target.value)}
									/>
								</div>
								<div className="form-group w-100">
									<label className="mb-0" htmlFor="date">Selecteer een datum</label>
									<select
										className="form-control"
										id="date"
										value={selectedEvent}
										onChange={(event) => setSelectedEvent(event.target.value)}
									>
										<option value="">Kies een datum</option>
										{infoNightEvents.map((event) => (
											<option key={event.id} value={event.id} disabled={event.remainingSpots === 0}>
												{event.name} - {moment(event.date).format("DD-MM-YYYY")}, {event.time} {event.remainingSpots > 10 ? "" : `(${event.remainingSpots} plekken beschikbaar)`}
											</option>
										))}
									</select>
								</div>
							</div>
							<button type="submit" className="btn btn-primary w-100 py-2 rounded-lg" disabled={loading} style={{ fontSize: "1.2rem" }}>
								{loading ? <i className="fas fa-spinner fa-spin"></i> : "Aanmelden"}
							</button>
						</form>
						<small className="form-text">U ontvangt een e-mail met de bevestiging!</small>
					</div>
				</div>
			</div>
		</div>
	);
};

export default React.memo(InfoNightSignup);
