import React from "react";

import NavbarLink from "../NavbarLink";

function LaadpaalSubNavbar() {
    return (
        <>
            <NavbarLink to="/laadpalen/" exact>
                <i className="fa-solid fa-house fa-fw mr-2" />
                Overzicht
            </NavbarLink>
            <NavbarLink to="/laadpalen/solaredge/" exact>
                <i className="fa-solid fa-charging-station fa-fw mr-2" />
                SolarEdge
            </NavbarLink>
            <NavbarLink to="/laadpalen/autel-ac-wallbox/" exact>
                <i className="fa-solid fa-charging-station fa-fw mr-2" />
                Autel AC Wallbox
            </NavbarLink>
            <NavbarLink to="/laadpalen/zakelijk/" exact>
                <i className="fa-solid fa-briefcase fa-fw mr-2" />
                Zakelijk
            </NavbarLink>
        </>
    );
}

export default React.memo(LaadpaalSubNavbar);
