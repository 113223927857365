import React from "react";
import {
    Link
} from "react-router-dom";

import BlackFridayCallToAction from "./BlackFridayCallToAction";

function CallToAction({ title = "Vraag vandaag nog een offerte aan!", subtitle = undefined, buttonText = "Offerte aanvragen", buttonLink = "/offerte/" }) {
    return (
        <React.Fragment>
            <div className="container mt-5">
                <BlackFridayCallToAction/>
            </div>
            <div className="container text-center pt-5 pb-5 mt-2">
                <div className="text-center" style={{fontSize: "2.5rem", fontWeight: "600"}}>{ title }</div>
                { subtitle && (
                    <p className="lead text-center">
                        <b>{ subtitle }</b>
                    </p>
                )}
                { buttonLink.startsWith("https://") ? (
                    <a href={ buttonLink } className="btn btn-primary btn-lg mt-3 px-4">
                        { buttonText }
                    </a>
                ) : (
                    <Link to={ buttonLink } className="btn btn-primary btn-lg mt-3 px-4">
                        { buttonText }
                    </Link>
                )}
            </div>
        </React.Fragment>
    )
}

export default React.memo(CallToAction);
