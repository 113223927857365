import React from "react";

import Helmet from "../components/Helmet";
import CallToAction from "../components/CallToAction";

import seInstallerPlusLogo from "../img/solaredge-installer-plus/solaredge-installer-plus.svg";
import awardPhoto from "../img/solaredge-installer-plus/award-photo.jpg";

import NavbarSettings from "../components/navbar/NavbarSettings";
import SubPageBanner from "../components/SubPageBanner";

function SolarEdgeInstallerPlus() {
    return (
        <React.Fragment>
            <Helmet
                title="SolarEdge Installer Plus"
                description="Smith Solar B.V. is SolarEdge Installer Plus! SolarEdge heeft ons aangewezen als zeer goed aangeschreven PV-installatiebedrijf."
            />

            <NavbarSettings>
                <SubPageBanner
                    title="SolarEdge Installer Plus"
                />
            </NavbarSettings>

            <div className="container container-sm mt-5">

                <div className="text-center mb-4 mb-md-5">
                    <a
                        href="https://marketing.solaredge.com/solaredge-installer-plus?email=installerplus@smithtechniek.nl"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            src={ seInstallerPlusLogo }
                            alt="SolarEdge Installer Plus"
                            className="img-fluid"
                            style={{ maxWidth: "300px" }}
                        />
                    </a>
                </div>

                <div className="article-open">
                    <p>
                        Smith Solar B.V. is SolarEdge Installer Plus! SolarEdge heeft ons aangewezen als zeer goed
                        aangeschreven PV-installatiebedrijf.
                    </p>
                </div>

                <h2>Wat is SolarEdge Installer Plus?</h2>
                <p>
                    Alleen installatiebedrijven met het hoogste niveau van expertise in onze SolarEdge PV-systemen
                    kunnen lid worden van dit programma. Deze installateurs hebben jarenlang ervaring opgebouwd als het
                    gaat om zonne-energiesystemen voor thuisgebruik, en hebben zich keer op keer in de praktijk bewezen
                    in de installatie van SolarEdge-producten en de implementatie van SolarEdge-oplossingen.
                </p>

                <p>
                    Er zijn talloze voordelen verbonden aan een installatiebedrijf dat lid is van SolarEdge Installer
                    Plus, waaronder:
                </p>
                <ul>
                    <li>
                        Professioneel advies over het ontwerp van uw PV-systeem en de keuze van de juiste componenten
                    </li>
                    <li>
                        Levering en implementatie van een PV-systeem van hoge kwaliteit
                    </li>
                    <li>
                        Uitstekende klantenservice na installatie
                    </li>
                    <li>
                        Service tijdens de gehele levensduur van het systeem
                    </li>
                    <li>
                        Een probleemloze verwerking van uw garantieaanvraag, mocht die ooit nodig zijn
                    </li>
                </ul>

                <p>
                    Alle leden van het Installer Plus-programma ontvangen een certificering van SolarEdge,
                    zoals te lezen is op de{" "}
                    <a
                        href="https://marketing.solaredge.com/solaredge-installer-plus?email=installerplus@smithtechniek.nl"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        website van SolarEdge
                    </a>.
                </p>

                <div className="my-4">
                    <img
                        src={ awardPhoto }
                        className="img-fluid rounded"
                        alt="SolarEdge Installer Plus award"
                    />
                    <small className="text-muted">
                        Zandor Smith (links) en Karel Smith (midden) nemen de SolarEdge Installer Plus award in ontvangst van Zvi Lando (rechts), CEO van SolarEdge Technologies.
                    </small>
                </div>

            </div>

            <CallToAction/>

        </React.Fragment>
    )
}

export default React.memo(SolarEdgeInstallerPlus);
