import React from "react";
import { Link } from "react-router-dom";

import Helmet from "../../components/Helmet";
import CallToAction from "../../components/CallToAction";
import NavbarSettings from "../../components/navbar/NavbarSettings";
import HomepageImageText from "../../components/HomepageImageText";

import autelAcWallbox from "../../img/charger/autel-ac-wallbox.png";
import autelAcWallbox2 from "../../img/charger/autel-2-voor.webp";
import alfenInGebruik from "../../img/charger/alfen-vrouw-gebruik.jpg"
import banner from "../../img/charger/ev-charger-banner.jpg";
import blockSeperator from "../../img/svg/block-seperator.svg";
import SubPageBanner from "../../components/SubPageBanner";

function LaadpaalZakelijk() {
    return (
        <React.Fragment>
            <Helmet
                title="Laadpalen Zakelijk"
                description="Ontdek de voordelen van zakelijke laadpalen met Smith Solar B.V. Bied uw klanten en collega's elektrische laadpunten aan met slimme oplossingen zoals de Autel AC Wallbox. Profiteer van subsidies en investeer in duurzame mobiliteit."
            />
            <NavbarSettings>
                <SubPageBanner
                    title="Zakelijke laadpalen"
                    subtitle="Bied uw klanten en collega's elektrische laadpunten aan"
                    image={alfenInGebruik}
                    imgAlt="Afbeelding van een zakelijke laadpaal"
                    offerteWidget
                    offerteType="laadpaal"
                />
            </NavbarSettings>

            <div className="container-orange-background">
                <div className="container">
                    <HomepageImageText
                        title="Autel AC Wallbox: De ideale zakelijke laadoplossing"
                        text={
                            <React.Fragment>
                                <ul className="pl-3">
                                    <li>RFID-scanner voor eenvoudige toegang</li>
                                    <li>4G-verbinding geïntegreerd voor altijd online functionaliteit</li>
                                    <li>Compatibel met OCPP voor koppeling met backoffice en betalingssystemen</li>
                                    <li>Geschikt voor zowel 1-fase (tot 7,4 kW) als 3-fase (tot 22 kW) laadcapaciteit</li>
                                </ul>
                                <p>
                                    De Autel AC Wallbox is perfect voor bedrijven die elektrische laadpunten willen aanbieden aan klanten of collega's. 
                                    Deze laadpaal biedt geavanceerde functies en kan eenvoudig worden beheerd via een backoffice-systeem.
                                </p>
                                <Link to="/offerte/" className="btn btn-primary">
                                    Offerte aanvragen
                                </Link>
                            </React.Fragment>
                        }
                        img={autelAcWallbox2}
                        imgAlt="Afbeelding van een Autel AC Wallbox laadpaal"
                    />
                </div>
            </div>
            <div className="container-white-background">
                <div className="container">
                    <HomepageImageText
                        title="Waarom een zakelijke laadpaal?"
                        text={
                            <React.Fragment>
                                <p>
                                    Het aanbieden van laadpalen is een slimme investering voor bedrijven die hun klanten en medewerkers willen ondersteunen in de transitie naar elektrisch rijden. Met een zakelijke laadoplossing kunt u:
                                </p>
                                <ul>
                                    <li>Uw klanten en medewerkers voorzien van een essentiële service.</li>
                                    <li>Bijdragen aan een duurzamer bedrijfsimago.</li>
                                    <li>Gebruikmaken van subsidies en fiscale voordelen voor laadpalen.</li>
                                    <li>Toekomstbestendig ondernemen door te anticiperen op de groei van elektrische voertuigen.</li>
                                </ul>
                            </React.Fragment>
                        }
                        img={banner}
                        coverImage
                        imgAlt="Afbeelding van een Autel AC Wallbox laadpaal"
                    />
                </div>
                <div className="container pt-4">
                    <HomepageImageText
                        title="Koppeling met OCPP Backoffice voor Betaling"
                        text={
                            <React.Fragment>
                                <p>
                                Het gebruik van een zakelijke laadpaal wordt nog efficiënter met een koppeling naar een OCPP backoffice. Hiermee heeft u volledige controle over uw laadstations en kunt u het laadproces eenvoudig beheren. Voordelen van een OCPP-koppeling zijn onder andere:
                                </p>
                                <ul>
                                    <li><strong>Betalingsbeheer:</strong> Bied uw bezoekers of klanten de mogelijkheid om eenvoudig te betalen voor het laden van hun voertuig. Dankzij de koppeling met een backoffice kunnen transacties automatisch worden verwerkt.</li>
                                    <li><strong>Overzicht en controle:</strong> Monitor laadactiviteiten en verbruik in real-time. Dit is ideaal voor bedrijven die laadpalen gebruiken voor hun eigen elektrische bedrijfsvoertuigen of als service aan klanten.</li>
                                    <li><strong>Flexibiliteit:</strong> Koppel uw laadpalen met een breed scala aan bekende backoffice-systemen, zodat u altijd een oplossing vindt die bij uw bedrijfsvoering past.</li>
                                </ul>
                                <p>Met deze integratie maakt u zakelijk laden niet alleen gemakkelijker, maar ook winstgevender. Wij helpen u graag met het instellen van de juiste OCPP-koppeling. Neem contact op voor advies of een offerte!</p>
                            </React.Fragment>
                        }
                        buttonLink={"/offerte/"}
                        buttonText="Offerte aanvragen"
                        img={alfenInGebruik}
                        isReverse
                        coverImage
                        imgAlt="Afbeelding van een Autel AC Wallbox laadpaal"
                    />
                </div>
            </div>

            <div>
                <div className="container">
                    <HomepageImageText
                        title="Zakelijke subsidies voor laadpalen"
                        text={
                            <React.Fragment>
                                <p>
                                    Voor sommige regio's zijn er subsidies beschikbaar voor bedrijven die investeren in laadpalen. Dit kan een aanzienlijke besparing opleveren op de aanschaf en installatie van uw laadoplossing.
                                </p>
                                <p>
                                    Wij helpen u graag met het aanvragen van subsidies en het realiseren van uw zakelijke laadoplossing. Neem contact met ons op voor meer informatie.
                                </p>
                            </React.Fragment>
                        }
                        img={autelAcWallbox}
                        imgAlt="Afbeelding van een Autel AC Wallbox laadpaal"
                        isReverse
                    />
                </div>
                <div className="w-full" style={{ height: 200, position: "relative" }}>
                    <img src={blockSeperator} alt="Block seperator" style={{ width: "100%", position: "absolute", objectFit: "cover", bottom: 0 }} />
                </div>
            </div>

            <div className="container-white-background">
                <div className="container container-sm pb-5 text-center">
                    <h2>Duurzame mobiliteit begint hier</h2>
                    <p>
                        Door laadpalen te installeren op uw zakelijke locatie, draagt u bij aan een groenere toekomst en biedt u een waardevolle service aan uw klanten en medewerkers. Smith Solar B.V. staat klaar om u te adviseren en te begeleiden bij het kiezen en installeren van de juiste laadoplossing.
                    </p>
                </div>
            </div>
                    <CallToAction />
        </React.Fragment>
    );
}

export default React.memo(LaadpaalZakelijk);
